body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.page-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  animation: fadeEffect 1s;
}

.menu-bar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 66px;
  margin: 16px;
  background-color: black;
}

.menu-bar a {
  color: white;
  text-transform: uppercase;
  margin: 0 32px;
  text-decoration: none;
}

@media screen and (max-width: 414px) {
  .menu-bar {
    flex-direction: column;
    height: initial;
  }

  .menu-bar a {
    margin: 8px auto;
  }
}