.status-message {
    padding: 32px;
    background-color: #dfdfdf;
    width: 80vw;
    border: 1px solid #343434;
    margin-top: 16px;
    margin-bottom: 16px;
}

.two-column-divs {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
    width: 80vw;
}

.two-column-divs > div {
    width: 100%;
    /* border: 1px solid #000; */
    display: flex;
    flex-direction: column;
}

.two-column-divs > div > div {
    width: 100%;
    /* border: 1px solid #000; */
    display: flex;
    flex-direction: column;  
}

label {
    text-align: left;
    font-weight: bold;
    font-size: 0.8rem;
}

input[type=text], input[type=email], input[type=number], input[type=password] {
    padding: 12px;
    box-sizing: border-box;
    margin: 8px;
    border-radius: 4px;
    border-style: solid;
    border-color: #c8c8c8;
    border-width: 1px;
}

input[type=button], button {
    background-color: red;
    border: none;
    color: white;
    padding: 16px 32px;
    font-size: 1.2rem;
    text-transform: uppercase;
    text-decoration: none;
    margin: 4px 2px;
    border-radius: 4px;
    cursor: pointer;
    display: block;
}

input[type=button]:disabled, button:disabled {
    background-color: rgba(255, 0, 0, 0.4);
}

select {
    padding: 8px;
    border-radius: 4px;
    height: 34px;
    margin: 8px;
    background-color: white;
    border-color: #c8c8c8;
  }

@media screen and (max-width: 414px) {
    .two-column-divs {
        flex-direction: column;
    }
}