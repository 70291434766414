.loader {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid red;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    display: block;
    animation: spin 1.2s ease-in infinite;
    margin: 16px;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
