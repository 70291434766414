.admin-lists-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.admin-lists-menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
}

.admin-list-panel {
    border-top: 1px solid red;
    animation: fadeEffect 1s;
    padding: 16px;
    border-left: 1px solid #787878;
    border-right: 1px solid #787878;
    border-bottom: 1px solid #787878;
}

.edit-icon {
    width: 24px;
    height: auto;
    margin: 16px;
}

.editing {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

@keyframes fadeEffect {
    from {opacity: 0;}
    to {opacity: 1;}
}

.admin-list-panel.hidden {
    display: none;
}

.small-warning {
    display: none;
    padding: 16px;
    width: 100%;
    background-color: orange;
    color: white;
}

table {
    width: 80vw;
    border-spacing: 0;
}

td {
    text-align: left;
    border: 1px solid #a0a0a0;
    padding: 8px;
}

thead tr {
    background-color: #f0f0f0;
    border: 1px solid #a0a0a0;
    padding: 8px;
}

thead > tr > td {
    font-weight: bold;
    background-color: #f0f0f0;
    /* border: none; */
    padding: 12px ;
}

.rc-table-cell {
    padding: 12px ;
}

button.small {
    padding: 8px 16px;
    font-size: 0.5rem;
}

button.medium {
    padding: 12px 20px;
    margin: 2px;
    border-radius: 0;
    font-size: 0.8rem;
}

@media screen and (max-width: 569px) {
    .small-warning {
        display: block;
    }
}