.villains-logo-main {
    width: 30vw;
    height: auto;
    display: block;
}

.event-button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.event-button {
    font-size: 0.8rem;
    margin: 0 8px;
    background-color: #e4002b;
}

.event-button-all {
    background-color: #041e42;
    border-width: 5px;
    font-size: 1rem;
}

.leaderboard {
    width: 90vw;
}

.leaderboard-list {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
}

.leaderboard-class {
    width: 100%;
    margin: 0 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: initial;
}

.leaderboard-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 8px;
    margin: 8px;
    align-items: center;
    background-color: black;
    border-radius: 8px;
    border: 1px solid black;
    color: white;
}

.leaderboard-item .team-gym-container {
    flex-grow: 1;
}

.leaderboard-item .team {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: left;
}

.leaderboard-item .gym {
    font-size: 0.8rem;
    font-weight: bold;
    text-align: left;
}

.leaderboard-item .rank {
    flex-grow: 0;
    margin-right: 16px;
    font-size: 3rem;
    color: red;
    text-align: left;
}

.leaderboard-item .score {
    font-weight: 100;
    font-size: 2rem;
    text-align: right;
}

.leaderboard-item .division-and-event {
    text-align: left;
    font-size: 0.5rem;
    padding-right: 8px;
}

@media screen and (max-width: 767px) {
    .leaderboard-item .team {
        font-size: 1.0rem;
    }

    .leaderboard-item .gym {
        font-size: 0.8rem;
    }
}

@media screen and (max-width: 768px) {
    .event-button-container {
        justify-content: flex-start;
    }
}

@media screen and (max-width: 414px) {
    .villains-logo-main {
        width: 80vw;
    }

    .leaderboard-list {
        flex-direction: column;
        align-items: center;
    }

    .leaderboard-class {
        align-items: initial;
    }

    .leaderboard-item .team {
        font-size: 1.4rem;
    }

    .leaderboard-item .gym {
        font-size: 1rem;
    }

    .event-button-container {
        justify-content: flex-start;
    }
}