footer {
    height: 30vh;
    background-color: #17181A;
    color: beige;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: 100vw;
    margin-top: 64px;
}

footer p {
    padding: 0;
    margin: 0;
    margin-bottom: 16px;
    text-align: center;
}

footer a {
    color: white;
}

footer small {
    font-size: 0.6rem;
}

@media screen and (max-width: 414px) {
    footer small {
        font-size: 1.0rem;
    }
}