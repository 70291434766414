button.login-button {
    margin: 4px auto !important;
}

.login-form > input {
    min-width: 20em;
}

@media screen and (max-width: 414px) {
    button.login-button {
        margin: 4px auto !important;
    }

    .login-form {
        width: 100%;
        text-align: center;
    }

    .login-form > input {
        width: 80%;
        margin: 0 auto;
    }
}